import React, { useState } from "react";
import Layout from "../../components/layout";
import CallOutSection from "../../components/calloutsection";
import { SEO } from "../../components/seo";
import { graphql } from "gatsby";
import { getPageByLanguage } from "../../hooks/get-page-by-language";
import useCookie from "../../hooks/use-cookie";
import { getBrowserLanguage } from "../../hooks/get-language";
import { getHeaderAndFooter } from "../../hooks/get-header-and-footer";

const brandsDirectory = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let foundBrandDir = getPageByLanguage(
    data.allContentstackBrandDirectory.nodes,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundBrandDir && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <CallOutSection
        calloutsection={foundBrandDir.brands_call_outs[0]}
        brandsDirectory={true}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackBrandDirectory {
      nodes {
        locale
        title
        brands_call_outs {
          section_title
          card_layout_3
          title_as_link_
          call_out_cards {
            title
            text
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            link {
              href
              title
            }
          }
        }
      }
    }
  }
`;

export default brandsDirectory;
export const Head = () => <SEO title="Elevate Outdoor Collective | Brands" />;
